.bannerDiv {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: white;
    padding: 20px;
    text-align: center;
    z-index: 1000;
}

.cookieButton {
    color: rgb(104, 85, 224);
    background-color: rgba(255, 255, 255, 1);
    border: 1px solid rgba(104, 85, 224, 1);
    width: 100px;
    border-radius: 999px;
    cursor: pointer;
}


.cookieButton:hover {
    color: white;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.6);
    background-color: rgba(104, 85, 224, 1);

}