.cardView {
  display: flex;
  width: 80%;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  gap: 20px;
}

.cardImg {
  width:100%;
  height: 250px;
  object-fit: contain;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
  flex: 1;
  text-align: center;
  height: 300px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}

.container {
  padding: 2px 16px;
}


@media screen and (max-width: 800px) {
  .cardView {
    display: flex;
    flex-direction: column;
    width: 80%;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    gap: 20px;
  }
}