#triggeredText {
    display: none;
}

.dataDiv {
    text-align: center;
}

#tokenInput {
    width: 200px;
    margin-right: 20px;
}

.userRequestDiv {

}

.userRequestTable, .visitStatTable {
    border-collapse: collapse;
    margin: auto;
}

.userRequestTable td, .userRequestTable th, .visitStatTable td, .visitStatTable th {
    padding: 10px;  /* Adjust this value as needed */
    border: 1px solid #ddd;  /* Adds a border to each cell */
}