.app-container {
    display: flex;
    min-height: 80vh;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.sideBarMenuDiv {
    flex: 1;
    height:100%;
}

.rightDiv {
    flex: 4;
    height:100%;
}

.w3-sidebar {
    padding: 20px;
    width: 240px;

    display: flex;
    flex-flow: column;
    gap: 30px;
}

.w3-bar-item {
    display: block;
    padding: 8px 16px;
    text-align: center;
}

.tablink {
    cursor: pointer;
    width: 100%;
    padding: 8px 0;
    border: none;
    background-color: transparent;
}

.w3-red {
    background-color: #d3e3fd;
}

.w3-card {

}

.w3-padding {
    padding: 8px 16px;
}

.city {
    display: none;
}

.city.active {
    display: block;
}