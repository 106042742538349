.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.dropdown {
    border-radius: 15px;
    height: 45px;
    text-align: center;
    font-size: 15px;
}

.dropdown-container {
    width: 35%;
    margin: 0 auto;
}

.dropdown-row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 20px;
}

.dropdown-row > div {
    flex: 1;
}

.dropdown-row > div:last-child {
    margin-right: 0;
}

#zipcode {
    height: 28px;
    width: 10%;
    margin-left: 15px;
}

#mainTitle, #subTitle {
    width: 100%;
    text-align: center;
    font-family: Futura, Helvetica, serif;
}

#searchButton {
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 0px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    height: 40px;
}

@keyframes button-loading-spinner {
    from {
        transform: rotate(0turn);
    }

    to {
        transform: rotate(1turn);
    }
}

#searchDiv {
    text-align: center;
    position: relative;
    margin-bottom: 40px;
}

#ofText {
    margin-left: 15px;
}

#submitForm {
    height: auto;
}

#miles, #ofText, #zipcode {
    display: inline-block;
}

#miles {
    margin-left: 0;
}

.bottomDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.infoLink:before
{
    content: '?';
    display: inline-block;
    font-family: sans-serif;
    font-weight: bold;
    text-align: center;
    width: 1.8ex;
    height: 1.8ex;
    font-size: 1.4ex;
    line-height: 1.8ex;
    border-radius: 1.2ex;
    margin-right: 4px;
    padding: 1px;
    color: blue;
    background: white;
    border: 1px solid blue;
    text-decoration: none;
}

.infoLink:hover:before
{
    color: white;
    background: blue;
    border-color: white;
    text-decoration: none;
}

.infoLink .tooltipText {
    visibility: hidden;
    width: auto;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
}

.infoLink .tooltipText:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 100%;
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

.infoLink:hover .tooltipText {
    visibility: visible;
}

@media screen and (max-width: 800px) {
    .dropdown {
        border-radius: 15px;
        height: 45px;
        margin-bottom: 15px;
        padding-left: 5px;
    }
    .dropdown-container {
        width: 85%;
        margin: 0 auto;
    }
}