:root {
  --background: #ffffff;
  --foreground: #171717;
  --primary: #4f46e5;
  --primary-hover: #4338ca;
  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-700: #374151;
  --gray-800: #1f2937;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #0a0a0a;
    --foreground: #ededed;
    --primary: #6366f1;
    --primary-hover: #4f46e5;
    --gray-50: #18181b;
    --gray-100: #27272a;
    --gray-200: #3f3f46;
    --gray-700: #d4d4d8;
    --gray-800: #e4e4e7;
  }
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  color: var(--foreground);
  background: var(--background);
  font-family: var(--font-geist-sans), Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.5;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

/* Navigation Styles */
nav {
  border-bottom: 1px solid var(--gray-200);
  margin-bottom: 2rem;
}

/* Form Elements */
input[type="file"],
select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--gray-200);
  border-radius: 0.375rem;
  background-color: var(--background);
  color: var(--foreground);
  margin: 0.5rem 0;
}

input[type="file"]:focus,
select:focus {
  outline: 2px solid var(--primary);
  outline-offset: 2px;
}

/* Button Styles */
button {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  font-weight: 500;
  transition: all 0.2s;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

/* Card/Container Styles */
.card {
  background: var(--background);
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  padding: 1.5rem;
  margin-bottom: 1.5rem;
}

/* Spacing Utilities */
.space-y-4 > * + * {
  margin-top: 1rem;
}

.space-y-6 > * + * {
  margin-top: 1.5rem;
}

/* Typography */
h1, h2, h3, h4, h5, h6 {
  color: var(--gray-800);
  margin-bottom: 1rem;
  font-weight: 600;
}

p {
  margin-bottom: 1rem;
  color: var(--gray-700);
}

/* Labels */
label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: var(--gray-700);
}

/* Grid Layout */
.grid {
  display: grid;
  gap: 1.5rem;
}

@media (min-width: 768px) {
  .grid-cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

/* Container */
.container {
  width: 100%;
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  padding-left: 1rem;
  padding-right: 1rem;
}

/* Responsive Padding */
@media (min-width: 640px) {
  .container {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

@media (min-width: 1024px) {
  .container {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
