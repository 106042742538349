.title {
    text-align: center;
    margin-bottom: 45px;
}

.searchDivTitle {
    cursor: pointer;
    margin-top: 0;
}

.windowLeft {
    width:25%;
    position:relative;
    float:left;
    height:100%;
}

.windowRight {
    width:75%;
    position:relative;
    float:left;
    height:100%;
}

.carImage {
    height: 250px;
    width: 350px;
    object-fit: cover;
    cursor: pointer;
}

.divForEachCar {
    height: 260px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;
}

.trimDropdown {
    width: 45%;
    border-radius: 15px;
    height: 45px;
}

.refineSearchDiv {
    text-align: center;
}

.refineSearchDropdownDiv {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.divLeft {
    flex: 1;
    height:100%;
    margin-right: 0;
}

.divRight {
    flex: 2;
    height:100%;
}

.divInsideRight {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

.insideLeft {
    flex: 2;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
}

.insideRight {
    flex: 1;
    height: 100%;
}

.insideLeft p{
    margin: 0;
}

.insideLeft h4{
    margin: 0;
}

#price {
    margin-top: 0;
}

.filter {
    margin-top: 30px;
    text-align: center;
}

/*Chip button*/
.chip {
    display: inline-flex;
    padding: 0 25px;
    height: 40px;
    font-size: 18px;
    border-radius: 25px;
    background-color: #f1f1f1;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}

.closebtn {
  padding-left: 10px;
  color: #888;
  font-weight: bold;
  float: right;
  font-size: 20px;
  cursor: pointer;
}

.closebtn:hover {
  color: #000;
}

.chipDiv {
    text-align: center;
    margin-top: 30px;
}

.carSold {
    display: none;
}

.interestedButton {
    background: #5E5DF0;
    border-radius: 999px;
    box-shadow: #5E5DF0 0 10px 20px -10px;
    box-sizing: border-box;
    color: #FFFFFF;
    cursor: pointer;
    font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
    font-size: 16px;
    font-weight: 700;
    line-height: 0px;
    opacity: 1;
    outline: 0 solid transparent;
    padding: 8px 18px;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    width: fit-content;
    word-break: break-word;
    border: 0;
    height: 40px;
}

.insideRightButtons {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 15px;
}

.saveButton {
    background-color: #fff;
    color: #6855e0;

    cursor: pointer;
    border: 0;
    border-radius: 4px;
    font-weight: 600;
    margin: 0 10px;
    width: 200px;
    padding: 10px 0;
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
}

.gradeDiv {
    display: flex;
    gap: 5px;
    align-items: center;
}

@media screen and (max-width: 800px) {
    .chipDiv {
        display: none;
    }
    .windowLeft {
        display: none;
    }
    .windowRight {
        width: 100%;
    }
    .carImage {
        height: 250px;
        width: 90%;
        object-fit: cover;
        cursor: pointer;
    }
    .divForEachCar {
        display: flex;
        flex-direction: column;
        height: 460px;
        margin-bottom: 50px;
        text-align: center;
        margin-top: 120px;
    }
    .divLeft {
        flex: 2;
    }
    .divRight {
        flex: 1;
    }

    .interestedButton {
        background: #5E5DF0;
        border-radius: 999px;
        box-shadow: #5E5DF0 0 10px 20px -10px;
        box-sizing: border-box;
        color: #FFFFFF;
        cursor: pointer;
        font-family: Inter, Helvetica, "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Noto Color Emoji", "Segoe UI Symbol", "Android Emoji", EmojiSymbols, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", sans-serif;
        font-size: 15px;
        font-weight: 700;
        line-height: 0px;
        opacity: 1;
        outline: 0 solid transparent;
        padding: 0;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        word-break: break-word;
        border: 0;
        height: 40px;
        width: 100%;
    }
}